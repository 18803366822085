@font-face {
    font-family: 'Sora';
    src: url('./fonts/Sora-Regular.woff2') format('woff2'),
         url('./fonts/Sora-Regular.woff') format('woff'),
         url('./fonts/Sora-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  
.main {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.main .overlap-group-wrapper {
    background-color: #ffffff;
    width: 1440px;
    height: 1024px;
}

.main .overlap-group {
    position: relative;
    height: 1024px;
    background-color: #ffeeee;
}

/* For the Cypherex SVG */
.main .main-content {
    position: absolute;
    width: 614px;
    height: 161px;
    top: 250px;
    left: 414px;
}

.main .svg-text {
    width: 100%;
    height: auto;
    max-width: 600px; /* Adjust based on your SVG design */
}

/* For the 'A New Way to Secure' SVG */
.main .div-wrapper {
    position: absolute;
    width: 525px;
    height: 60px;
    top: 400px;
    left: 465px;
}

/* For the 'Coming Soon' SVG */
.main .main-content-2 {
    position: absolute;
    width: 444px;
    height: 81px;
    top: 648px;
    left: 500px;
}

/* Options (contact, GitHub, about) remain unchanged */
.main .options {
    position: absolute;
    width: 635px;
    height: 30px;
    top: 27px;
    left: 433px;
}

.main .contact-us {
    position: absolute;
    width: 139px;
    height: 30px;
    top: 0;
    left: 498px;
}

.main .text-wrapper-3 {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgb(246, 188, 255) 0%, rgb(255, 158, 251) 51%, rgb(177, 208, 255) 100%);
    -webkit-background-clip: text !important;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Sora-Light", Helvetica;
    font-weight: 300;
    color: transparent;
    font-size: 24px;
    letter-spacing: 0;
    line-height: normal;
}

.main .github {
    position: absolute;
    width: 86px;
    height: 30px;
    top: 0;
    left: 245px;
}

.main .about {
    width: 78px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
}
